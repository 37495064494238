import { ICondition } from '../../../types/audience';
import { modifiedDate } from '../../../utils/DateUtil';
import { IDateRange } from '../../spotlight/spotlight';
import { reportInitialData } from './constants';
import { IReportQuery, IReportStore } from './report';

//return value for id
export const getStoreLabel = (store_key: string, id: string, reportStore: IReportStore) => {
  const { trigger_store, cohort_store } = reportStore;

  // trigger store
  if (store_key === 'trigger') {
    const required_key = trigger_store.find(t => t.trigger_name === id);
    return required_key ? required_key.trigger_name : id;
  }

  // trigger store
  else if (store_key === 'cohort') {
    const required_key = cohort_store.find(t => t.cohort_id === id);
    return required_key ? required_key.cohort_name : id;
  }
};

//return store Array
export const getStoreData = (
  store_key: string,
  reportStore: IReportStore,
): Array<{ label: string; value: string; platform?: string; status?: string; created_at?: string }> => {
  const required_key = `${store_key}_store`;

  const results: Array<{
    label: string;
    value: string;
    platform?: string;
    status?: string;
    created_at?: string;
    question_order?: number;
    survey_name?: string;
  }> = [];

  if (required_key === 'trigger_store') {
    const trigger_data = reportStore[required_key] ? reportStore[required_key] : [];
    if (trigger_data.length > 0) {
      for (const i in trigger_data) {
        const { trigger_name } = trigger_data[i];
        results.push({ label: trigger_name, value: trigger_name });
      }
    }
  }

  // cohort_store array
  else if (required_key === 'cohort_store') {
    const cohort_data = reportStore[required_key] ? reportStore[required_key] : [];
    if (cohort_data.length > 0) {
      for (const i in cohort_data) {
        const { cohort_name, cohort_id } = cohort_data[i];
        results.push({ label: cohort_name, value: cohort_id });
      }
    }
  }

  return results;
};

export const getDashboardDate = (date_range: IDateRange) => {
  const { type, time_from, time_to, days_count } = date_range;
  if (type === 'custom') {
    const format_time_from = modifiedDate(time_from, 'DD MMM YYYY');
    const format_time_to = modifiedDate(time_to, 'DD MMM YYYY');
    return `${format_time_from} - ${format_time_to}`;
  } else if (type === 'days_since') {
    const format_time_from = modifiedDate(time_from, 'DD MMM YYYY');
    return `From ${format_time_from}`;
  }

  return `Last ${days_count} days`;
};

export const handleScaleValue = (value: number) => {
  if (value === null || value === undefined || +value === -1) return '';
  return value;
};

//reset query values
export const resetQueryObj = (full_reset: boolean) => {
  const { triggers, date_range, cohorts } = reportInitialData;

  const resetQueryKeys: any = {
    triggers,
  };

  //triggers, cohorts, attributes only reset on full reload (new chart/chart_id)
  if (full_reset) {
    date_range.type = 'days';
    date_range.days_count = 30;
    cohorts.data = [];
    triggers.data = [];
    resetQueryKeys.cohorts = cohorts;
    resetQueryKeys.triggers = triggers;
    resetQueryKeys.date_range = date_range;
    resetQueryKeys.attributes = [];
  }

  return resetQueryKeys;
};

export const resetChangesArray = (
  query_key: string,
  reportStore: IReportStore,
  action_type: string,
  data: Array<string>,
  id: string,
  search_str: string,
): Array<string> => {
  const store_data = getStoreData(query_key, reportStore);

  if (!action_type) {
    let results_arr = data;

    //if values exist remove otherwise add value
    if (data.includes(id)) {
      results_arr = data.filter(s => s !== id);
    } else {
      results_arr.push(id);
    }

    return results_arr;
  }

  //store_data copy
  let modified_store_data = store_data;

  if (action_type === 'select_all') {
    //remove values based search string

    if (search_str) {
      modified_store_data = store_data.filter(
        ({ label, value }) => label && (data.includes(value) || label.toLowerCase().includes(search_str)),
      );
    }

    const values_arr = modified_store_data.map(s => s.value);
    return values_arr;
  }

  if (action_type === 'clear_all') {
    //action clear_all reset values
    //if no search_str default [] return
    if (search_str) {
      modified_store_data = store_data.filter(
        ({ label, value }) => label && !label.toLowerCase().includes(search_str) && data.includes(value),
      );

      const values_arr = modified_store_data.map(s => s.value);
      return values_arr;
    }
  }

  return [];
};

export const validateAttributesQuery = (conditions: Array<ICondition>) => {
  if (conditions && conditions.length > 0) {
    for (let i = 0; i < conditions.length; i++) {
      const { attribute, comparator, data_type, list, value } = conditions[i];
      if (attribute.trim() === '') {
        return { success: false, message: 'Attribute cannot be empty' };
      }
      if (comparator.trim() === '') {
        return { success: false, message: 'Comparator cannot be empty' };
      }
      if (data_type === 'string' && list.length === 0) {
        return { success: false, message: 'Attribute values cannot be empty' };
      }
      if (data_type !== 'string' && (value === undefined || value === null || value === '')) {
        return { success: false, message: 'Attribute value cannot be empty' };
      }
    }
  }
  return { success: true, message: '' };
};
