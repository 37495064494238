import React, { useEffect, useState } from 'react';
import {
  Grid,
  Theme,
  Box,
  Typography,
  Button,
  Dialog,
  TextField,
  Select,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  List,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import crossImg from '../../../images/editor/cross-icon.svg';
import addImg from '../../../images/spotlight/add-icon.svg';
import { ReactComponent as SearchIcon } from '../../../images/audience/search-icon.svg';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { modifiedDate } from '../../../utils/DateUtil';
import { surveyStatusFeature } from '../../../static/staticData';
import { useStyles } from '../../spotlight/Spotlight.style';
import { IReportQuery, IReportStore } from './report';
import { reportFetchData, reportInitialData } from './constants';
import { fetchAllCohort } from '../../../services/audienceService';
import { QueryComponent } from './QueryComponent';
import { ICondition } from '../../../types/audience';
import { resetChangesArray, resetQueryObj } from './HelperUtil';
import { sourceInsightImgs } from '../../../static/connectionData';
import { IReportMeta } from '../../../types/insight';

const commonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderBottom: '1px solid #e8e8e8',
    },
    bottomSection: { paddingInline: theme.spacing(5), paddingBottom: theme.spacing(5) },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(4),
      display: 'flex',
      float: 'right',
    },
    activeOption: {
      width: '10vw',
      borderRadius: 5,
      backgroundColor: 'rgba(57, 84, 255, 0.11)',
      paddingBlock: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(0.2),
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    defaultOption: {
      width: '10vw',
      borderRadius: 5,
      paddingBlock: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.2),
      fontWeight: 'bold',
      cursor: 'pointer',
      color: 'gray',
    },
  }),
);

export default function ReportFilter(props: {
  toggleModal: (boolean) => void;
  open: boolean;
  reportMeta: IReportMeta;
  handleMetaTextChange: (field_name: string, field_value: string) => void;
  handleFilterSave: () => void;
}) {
  const classes = useStyles();
  const common = commonStyles();

  const { toggleModal, open, reportMeta, handleMetaTextChange, handleFilterSave } = props;
  const { report_name, keywords_include, triggers, date_range, cohorts, attributes } = reportMeta;

  const [activeOption, setActiveOption] = useState<number>(0);
  const [searchString, setSearchString] = useState('');
  const [activeName, setActiveName] = useState('');
  const [reportQuery, setReportQuery] = useState<IReportQuery>(reportInitialData);
  const [reportStore, setReportStore] = useState<IReportStore>(reportFetchData);

  const setInitialData = async () => {
    const cohorts = await fetchAllCohort();

    if (cohorts && cohorts.length > 0) {
      setReportStore({
        ...reportStore,
        cohort_store: cohorts,
      });
    }
  };

  useEffect(() => {
    setInitialData();
  }, []);

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handleOpen = (name: string) => {
    setActiveName(name);
  };

  const handleClose = (e: any, default_close: boolean) => {
    if (!default_close) {
      if (e && e.target && e.target.className) {
        if (!e.target.className.includes('MuiBackdrop-root')) {
          return;
        }
      }
    }

    setActiveName('');
    setSearchString('');
  };

  const handleAttributesQuery = (conditions: Array<ICondition>) => {
    setReportQuery({
      ...reportQuery,
      attributes: [...conditions],
    });
  };

  //return store Array
  const getStoreData = (
    store_key: string,
    reportStore: IReportStore,
  ): Array<{
    label: string;
    value: string;
    platform?: string;
    status?: string;
    created_at?: string;
  }> => {
    const required_key = `${store_key}_store`;

    const results: Array<{
      label: string;
      value: string;
      platform?: string;
      status?: string;
      created_at?: string;
      question_order?: number;
      survey_name?: string;
    }> = [];

    // cohort_store array
    if (required_key === 'cohort_store') {
      const cohort_data = reportStore[required_key] ? reportStore[required_key] : [];
      if (cohort_data.length > 0) {
        for (const i in cohort_data) {
          const { cohort_name, cohort_id } = cohort_data[i];
          results.push({
            label: cohort_name,
            value: cohort_id,
          });
        }
      }
    }

    return results;
  };

  const formatSelectListValue = (name: string, allSelectedOptions: any) => {
    const newText: Array<string> = [];
    const MAX_CHARS = 60;
    for (let i = 0; i < allSelectedOptions.length; i++) {
      const label = '';
      if (label) {
        newText.push(label);
      }
      if (newText.toString().length > MAX_CHARS) {
        break;
      }
    }
    return newText.toString().substring(0, MAX_CHARS);
  };

  const getQueryData = (query_key: string) => {
    const required_key = query_key === 'theme' ? 'options' : `${query_key}s`;
    return reportQuery[required_key] && reportQuery[required_key].data ? reportQuery[required_key].data : [];
  };

  /**
   * handle query updates & update state
   * @param query_key
   * @param id
   * @param secondary_label used by scale value to update nested keys
   */
  const handleQueryUpdates = (
    query_key: string,
    id: string,
    secondary_label?: string,
    action_type = '',
    search_str = '',
  ) => {
    // for setting the question_type value in SpotlightQuery
    if (query_key === 'question_type') {
      const resetQueryKeys = resetQueryObj(false);
      setReportQuery({
        ...reportQuery,
        ...resetQueryKeys,
        question_type: id,
      });
    }

    // for setting other values
    else {
      const required_key = query_key === 'theme' ? 'options' : `${query_key}s`;

      //fetch required query object
      const required_query = reportQuery[required_key];
      if (!required_query) {
        return;
      }

      const { data } = required_query;
      //fetch data from store
      required_query.data = resetChangesArray(query_key, reportStore, action_type, data, id, search_str.toLowerCase());

      setReportQuery({
        ...reportQuery,
        [required_key]: required_query,
      });
    }
  };

  //List View Item for select options
  const ListItem = (
    allSelectedOptions: Array<string>,
    name: string,

    item: {
      label: string;
      value: string;
      platform?: string;
      status?: string;
      created_at?: string;
      question_order?: number;
      survey_name?: string;
    },
    idx: number,
  ) => {
    const { label, value, platform, status, created_at, question_order, survey_name } = item;
    return (
      <ListItemButton key={idx} className={classes.multiListItem} onClick={() => handleQueryUpdates(name, value)} dense>
        <ListItemIcon>
          <Checkbox
            icon={
              <CheckBoxOutlineBlankIcon
                style={{
                  fontSize: '1.1rem',
                }}
              />
            }
            checkedIcon={
              <CheckBoxIcon
                style={{
                  fontSize: '1.1rem',
                }}
              />
            }
            classes={{
              root: classes.check,
              checked: classes.checked,
            }}
            checked={allSelectedOptions.includes(value)}
          />
        </ListItemIcon>
        {name === 'survey' && platform && status && created_at ? (
          <>
            <Box display="flex" alignItems={'center'}>
              <img
                src={platform ? `https://cdn.blitzllama.com/survey/platform/${platform}-sq.svg` : ''}
                alt=""
                style={{
                  marginLeft: 'auto',
                  height: '26px',
                }}
              />
              <Box ml={1}>
                <Typography variant="subtitle1" lineHeight={1.05}>
                  {label}
                </Typography>

                <div
                  style={{
                    marginTop: '-2px',
                  }}
                >
                  <Typography
                    display="inline"
                    color={surveyStatusFeature[status].color}
                    className={classes.listSubtitle}
                  >
                    {status}{' '}
                  </Typography>
                  <Typography display="inline" color="text.secondary" className={classes.listSubtitle}>
                    | {modifiedDate(created_at, 'DD MMM YY')}
                  </Typography>
                </div>
              </Box>
            </Box>
            <Box marginLeft={'auto'}>{status && surveyStatusFeature[status]?.icon}</Box>
          </>
        ) : name === 'question' && question_order && survey_name ? (
          <Box>
            <div>
              <Typography display="inline" color={'text.secondary'} className={classes.listSubtitle}>
                Q{question_order}{' '}
              </Typography>
              <Typography display="inline" color="text.secondary" className={classes.listSubtitle}>
                | {survey_name}
              </Typography>
            </div>
            <Typography variant="subtitle1" mt={-0.5} lineHeight={1.2} mb={0.8}>
              {label}
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle1">{label}</Typography>
        )}
      </ListItemButton>
    );
  };

  const selectListOptions = (name: string) => {
    const allSelectedOptions = getQueryData(name);
    let allOptions = getStoreData(name, reportStore);

    //filter based on search str
    if (searchString.length > 0) {
      allOptions = allOptions.filter(o => o.label && o.label.toLowerCase().includes(searchString.toLowerCase()));
    }

    return (
      <Select
        variant="outlined"
        className={classes.activeSelectField}
        style={{
          maxWidth: '70%',
          position: 'relative',
        }}
        renderValue={() =>
          allSelectedOptions && allSelectedOptions.length > 0 ? (
            <Typography variant={'subtitle1'} width={'30ch'} noWrap>
              {formatSelectListValue(name, allSelectedOptions)}
            </Typography>
          ) : (
            <Typography variant="subtitle1" color={'primary.contrastText'}>
              {name === 'category' ? 'All categories' : `All ${name === 'survey' ? `active` : ''} ${name}s`}
            </Typography>
          )
        }
        value={allSelectedOptions.length > 0 ? allSelectedOptions : `All ${name}s`}
        name={name}
        open={activeName === name}
        onOpen={() => handleOpen(name)}
        onClose={e => handleClose(e, false)}
      >
        <TextField
          fullWidth
          placeholder="Search a value..."
          className={classes.searchTextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => {
            setSearchString(e.target.value);
          }}
        />

        <Box
          style={{
            maxHeight: 450,
            overflowY: 'auto',
          }}
        >
          {allSelectedOptions.length > 0 && (
            <>
              <Box display={'flex'} justifyContent="space-between" mt={2}>
                <Typography fontSize={13} ml={2}>
                  Selected
                </Typography>
                <Typography
                  color={'text.secondary'}
                  fontSize={13}
                  mr={2}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleQueryUpdates(name, '', '', 'clear_all', searchString)}
                >
                  Clear All
                </Typography>
              </Box>
              <List className={classes.searchList}>
                {allOptions.map(
                  (item, idx) =>
                    item &&
                    item.label &&
                    allSelectedOptions.includes(item.value) &&
                    ListItem(allSelectedOptions, name, item, idx),
                )}
              </List>
              <hr className={classes.selectHrLine} />
            </>
          )}
          <Box display={'flex'} alignItems={'center'} justifyContent="space-between" mt={2} mb={1}>
            <Typography fontSize={13} ml={2}>
              {name === 'category' ? 'All categories' : `All ${name}s`}
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={700}
              className={classes.selectAllBox}
              onClick={() => handleQueryUpdates(name, '', '', 'select_all', searchString)}
            >
              Select All
            </Typography>
          </Box>
          <List
            className={classes.searchList}
            style={{
              paddingBottom: 50,
            }}
          >
            {allOptions.map((item, idx) => item && item.label && ListItem(allSelectedOptions, name, item, idx))}
          </List>
        </Box>

        <Box className={classes.applySelectBox}>
          <Button variant="contained" className={classes.applyButton} onClick={e => handleClose(e, true)}>
            <Typography fontSize={13} fontWeight={700} textAlign="right">
              Apply
            </Typography>
          </Button>
        </Box>
      </Select>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={'lg'}>
        <Grid container className={common.root}>
          <Grid item lg={12}>
            <Typography variant={'h4'} mb={1}>
              Report Settings
            </Typography>
            <Typography variant={'subtitle1'} mb={1}>
              Define what exactly we should look for and from which set of users to automatically generate this report
              for your team
            </Typography>
            <Box>
              <Typography variant={'h6'}>{report_name}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={common.bottomSection}>
          <Grid
            item
            lg={3}
            style={{
              borderRight: '1px solid #e8e8e8',
            }}
          >
            <Box my={5}>
              <Typography
                className={activeOption === 0 ? common.activeOption : common.defaultOption}
                onClick={() => setActiveOption(0)}
              >
                Users & triggers
              </Typography>
              <Typography
                className={activeOption === 1 ? common.activeOption : common.defaultOption}
                onClick={() => setActiveOption(1)}
              >
                Keywords
              </Typography>
              <Typography
                className={activeOption === 2 ? common.activeOption : common.defaultOption}
                onClick={() => setActiveOption(2)}
              >
                Sources
              </Typography>
            </Box>
          </Grid>
          {activeOption === 0 && (
            <Grid item lg={9}>
              <Box m={5}>
                <Typography variant={'subtitle1'} color={'gray'} fontWeight={'bold'} mb={1}>
                  Discover insights from
                </Typography>
                {/* <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" mr={1} fontWeight={700}>
                    Users
                  </Typography>
                  {selectListOptions('cohort')}
                </Box> */}
                <QueryComponent
                  reportQuery={reportQuery}
                  reportStore={reportStore}
                  handleQueryUpdates={handleQueryUpdates}
                  setReportQuery={setReportQuery}
                  handleAttributesQuery={handleAttributesQuery}
                />
              </Box>
            </Grid>
          )}
          {activeOption === 1 && (
            <Grid item lg={9}>
              <Box m={5}>
                <Typography variant={'subtitle1'} color={'gray'} fontWeight={'bold'} mb={1}>
                  Discover insights from
                </Typography>
                <Box p={2} border={'1px solid rgba(214, 209, 222, 0.38)'}>
                  <Typography variant="subtitle2" fontWeight={700} mb={1}>
                    Scan feedback for:
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder="Enter feature names, terms, or other keywords to monitor..."
                    value={keywords_include}
                    className={classes.searchTextField}
                    onChange={e => {
                      handleMetaTextChange('keywords_include', e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          {activeOption === 2 && (
            <Grid item lg={9}>
              <Box m={5}>
                <Typography variant={'subtitle1'} color={'gray'} fontWeight={'bold'} mb={1}>
                  Consolidate feedback & insights from
                </Typography>
                <Box
                  p={1.5}
                  border={'1px solid rgba(214, 209, 222, 0.38)'}
                  bgcolor={'#f9f9f9'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box display={'flex'}>
                    <img src={sourceInsightImgs['playstore']} height={17} style={{ marginTop: 4 }} />
                    <Typography variant={'subtitle1'} fontWeight={'bold'} ml={1}>
                      Playstore reviews
                    </Typography>
                  </Box>
                  <Typography variant={'subtitle2'} color={'gray'}>
                    Disconnect
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                handleFilterSave();
              }}
            >
              Save and apply
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </div>
  );
}
