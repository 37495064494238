import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Box, CircularProgress, Theme, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import filterImg from '../../../images/surveyPerformance/filter-icon.svg';
import aiStarImg from '../../../images/editor/ai-star.svg';

import { amplitudeEvent } from '../../../utils/amplitudeUtil';
import { RootState } from '../../../store';
import { capitalizeFirstLetter, userImgFormat } from '../../../utils/stringUtil';
import CountUp from 'react-countup';
import ReportFilter from './FilterDialog';
import { useParams } from 'react-router-dom';
import { getReportById, updateReportById } from '../../../services/insightService';
import { IReportItem, IReportMeta } from '../../../types/insight';
import { DailyChartComponent } from '../../spotlight/charts/DailyChartComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    title: { fontSize: '1.5rem', marginBottom: theme.spacing(4) },
    buttonSection: {
      marginTop: theme.spacing(4),
      display: 'flex',
      float: 'right',
    },
    filterButton: {
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      fontSize: '0.9rem',
      textTransform: 'none',
      padding: '0.3rem 0.75rem 0.3rem 0.75rem',
      fontWeight: 700,
      borderRadius: '5px',
      marginLeft: '9px',
      marginTop: '-5px',
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    topicItem: {
      borderRadius: theme.spacing(1),
      border: '1px solid rgba(214, 209, 222, 0.38)',
      padding: theme.spacing(3),
    },
    aiSurveyBox: {
      backgroundColor: '#fefbf4',
      padding: theme.spacing(2),
      border: '1px solid rgba(214, 209, 222, 0.38)',
    },
    violetText: {
      color: '#450f58',
    },
    aiSurveyBtn: {
      borderRadius: theme.spacing(1),
      border: '1px solid rgba(214, 209, 222, 0.38)',
      padding: theme.spacing(1),
      backgroundColor: 'white',
      cursor: 'pointer',
      width: '158px',
      marginLeft: theme.spacing(1.5),
    },
  }),
);

interface Iparams {
  report_id: string;
}

export default function ReportItemComponent() {
  const classes = useStyles();
  const params: Iparams = useParams();
  const report_id = params.report_id;

  const [isLoading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<Array<IReportItem>>([]);
  const [reportMeta, setReportMeta] = useState<IReportMeta | null>();

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'report item tab viewed', {});
    }
  }, [tracking_info]);

  const getReportInfoById = async () => {
    if (!report_id) {
      return;
    }

    const results = await getReportById(report_id);
    if (results && results.report_info) {
      const { report_info, ...otherProps } = results;

      setReportInfo(report_info);
      setReportMeta(otherProps);
    }
  };

  const handleMetaTextChange = (field_name: string, field_value: string) => {
    if (!reportMeta) {
      return;
    }

    if (field_name === 'keywords_include') {
      setReportMeta({ ...reportMeta, [field_name]: field_value });
    }
  };

  const handleFilterSave = async () => {
    if (!reportMeta) {
      return;
    }

    const { date_range, cohorts, attributes, keywords_include, report_name } = reportMeta;
    const update_changes = await updateReportById(report_id, {
      report_name,
      date_range,
      cohorts,
      attributes,
      keywords_include,
    });

    if (update_changes) {
      await getReportInfoById();
    }
  };

  useEffect(() => {
    getReportInfoById();
  }, [report_id]);

  if (!isLoading || !reportMeta) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    console.log('reportMeta --->', reportMeta);
    return (
      <div>
        {reportMeta && (
          <ReportFilter
            open={openFilter}
            toggleModal={() => {
              setOpenFilter(!openFilter);
            }}
            reportMeta={reportMeta}
            handleMetaTextChange={handleMetaTextChange}
            handleFilterSave={handleFilterSave}
          />
        )}
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={5}>
          <div>
            <Typography variant="h2" mb={3}>
              {reportMeta.report_name}
            </Typography>
            <Box display={'flex'}>
              {/* <Typography variant="subtitle1" mr={2}>
                <span style={{ fontWeight: 'bold' }}>202</span> reports
              </Typography>
              <Typography variant="subtitle1">
                <span style={{ fontWeight: 'bold' }}>90%</span> negative sentiment
              </Typography> */}
            </Box>
          </div>
          <div>
            <Box display={'flex'} alignItems={'flex-end'} flexDirection={'row'}>
              <Button
                className={classes.filterButton}
                style={{
                  color: 'black',
                  marginLeft: 10,
                }}
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <img src={filterImg} alt="" />
                  <Typography variant="subtitle1" fontWeight={700} ml={1}>
                    Filter
                  </Typography>
                </Box>
              </Button>
              <Button
                className={classes.filterButton}
                aria-controls="customized-menu"
                endIcon={<ExpandMoreIcon />}
                onClick={() => {}}
                style={{
                  color: 'black',
                }}
              >
                Share & export
              </Button>
            </Box>
          </div>
        </Box>
        {/* <Typography variant="subtitle1" mt={5} mb={3}>
          <span style={{ fontWeight: 'bold' }}>392</span> Identified from
          <span style={{ fontWeight: 'bold' }}> 97349</span> user inputs negative sentiment
        </Typography> */}

        {reportInfo.map((item, idx) => (
          <Grid container className={classes.topicItem} mb={3}>
            <Grid item lg={7}>
              <Box>
                <Typography variant={'body1'} fontWeight={'bold'} mb={1}>
                  {item.theme_name}
                </Typography>
                <Typography variant={'subtitle2'} mb={1}>
                  {item.summary}
                </Typography>

                <Typography variant={'subtitle2'} fontWeight={'bold'} color={'gray'} mb={1}>
                  What your users are saying?
                </Typography>

                {item.representative_docs &&
                  item.representative_docs.split('##').map(doc => (
                    <Box display={'flex'}>
                      <img src={userImgFormat('', '').userImg} width={12} />
                      <Typography variant={'subtitle2'} color={'gray'} ml={1}>
                        {doc}
                      </Typography>
                    </Box>
                  ))}

                <Typography variant={'subtitle2'} fontWeight={'bold'} mt={0.5} mb={2}>
                  Show more
                </Typography>

                <Box
                  className={classes.aiSurveyBox}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <div>
                    <Typography
                      className={classes.violetText}
                      variant={'subtitle1'}
                      fontWeight={'bold'}
                      mt={0.5}
                      mb={2}
                    >
                      Get more validation on this topic
                    </Typography>
                    <Typography className={classes.violetText} variant={'subtitle2'} mt={0.5} mb={2}>
                      {item.assumptions}
                    </Typography>
                  </div>
                  <Box display="flex" height={'fit-content'}>
                    <Box className={classes.aiSurveyBtn} display="flex" alignItems="center" ml={'auto'}>
                      <img src={aiStarImg} alt="" />
                      <Typography variant="subtitle1" color="info.contrastText" pl={0.5}>
                        Generate AI-survey
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} pl={3}>
              <Typography variant={'body1'} fontWeight={'bold'} mb={1}>
                Why it matters
              </Typography>
              <Box display={'flex'} justifyContent={'space-between'} width={'95%'}>
                <div>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    <CountUp end={item.reports} duration={3} />
                  </Typography>
                  <Typography variant="subtitle2" color={'gray'}>
                    Reports
                  </Typography>
                </div>

                <div>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    <CountUp end={item.average_weekly_reports} duration={3} />
                  </Typography>
                  <Typography variant="subtitle2" color={'gray'}>
                    Weekly Average
                  </Typography>
                </div>

                <div>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    {capitalizeFirstLetter(item.intent)}
                  </Typography>
                  <Typography variant="subtitle2" color={'gray'}>
                    Intent
                  </Typography>
                </div>

                <div>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    {capitalizeFirstLetter(item.sentiment)}
                  </Typography>
                  <Typography variant="subtitle2" color={'gray'}>
                    Sentiment
                  </Typography>
                </div>

                <div>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    {item.sentiment_perc}
                  </Typography>
                  <Typography variant="subtitle2" color={'gray'}>
                    Sentiment%
                  </Typography>
                </div>
              </Box>
              <Typography variant={'body1'} fontWeight={'bold'} mt={5}>
                Occurance
              </Typography>

              <Box width={'96%'} mt={2} mr={1}>
                <DailyChartComponent
                  x_axis={item.chart && item.chart.x_axis ? item.chart.x_axis : []}
                  data_groups={item.chart && item.chart.data_groups ? item.chart.data_groups : []}
                  question_type={'reports'}
                  chartHeight={250}
                  is_dashboard={true}
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }
}
