import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../pages/login/LogInPage';
import SignUp from '../pages/login/SignUpPage';
import LoginRedirect from '../pages/login/LoginRedirectPage';
import ForgetPassword from '../pages/login/ForgetPasswordPage';
import ChangePassword from '../pages/login/ChangePasswordPage';
import ResetPassword from '../pages/login/ResetPasswordPage';
import RedirectFromBoard from '../pages/login/RedirectFromBoardPage';

// integration
import TriggerTable from '../pages/integration/TriggerPage';

// Survey
import Welcome from '../pages/login/WelcomePage';
import OrgDetails from '../pages/login/OrgDetailsPage';
import DashboardDetails from '../pages/dashboard/DashboardDetailsPage';
import PublicDashboard from '../pages/dashboard/PublicDashboardPage';
import Template from '../pages/survey/TemplatePage';
import Collection from '../pages/survey/CollectionPage';
import SurveyHome from '../pages/survey/SurveyHome';
import Spotlight from '../pages/spotlight/SpotlightPage';
import Dashboard from '../pages/dashboard/DashboardPage';
import SurveyPerformance from '../pages/survey/PerformancePage';
import SurveyEditor from '../pages/editor/SurveyEditorPage';
import AudienceHome from '../pages/audience/AudienceHomePage';
import CohortHistory from '../pages/audience/CohortHistoryPage';
import CohortDetails from '../pages/audience/CohortDetailsPage';
import SurveySettings from '../pages/survey/SettingsPage';
import Error from '../pages/survey/ErrorPage';

import InsightsPage from '../pages/insights/InsightsPage';
import RespositoryPage from '../pages/insights/RespositoryPage';
import CreateCSVPage from '../pages/insights/CreateCSVPage';
import ReportHomePage from '../pages/insights/ReportHomePage';
import ReportItemPage from '../pages/insights/ReportItemPage';

import OrganisationSettings from '../pages/organisation/SettingsPage';
import Billing from '../pages/organisation/BillingPage';
import BillingPlan from '../pages/organisation/BillingPlanPage';
import Profile from '../pages/organisation/ProfilePage';
import AddDetails from '../pages/login/AddDetailsPage';

import Connection from '../pages/connection/ConnectionPage';

import StartProject from '../pages/organisation/StartProjectPage';
import { EditorRoute, AdminRoute, UserRoute } from './ProtectedRoute';

export const RouterConfig = () => {
  return (
    <Switch>
      {/* List all public routes here */}
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/auth">
        <LoginRedirect />
      </Route>
      <Route path="/forget-password">
        <ForgetPassword />
      </Route>
      <Route path="/change-password">
        <ChangePassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/welcome">
        <Welcome />
      </Route>

      <Route path="/report/:board_id">
        <PublicDashboard />
      </Route>

      <Route path="/start-project" exact component={StartProject} />

      <Route path="/r">
        <RedirectFromBoard />
      </Route>

      <Route path="/error">
        <Error />
      </Route>

      <EditorRoute path="/trigger" exact component={TriggerTable} />

      {/* Survey */}
      <UserRoute path="/board/:board_id" exact component={DashboardDetails} />

      <UserRoute path="/" exact component={SurveyHome} />

      <Route exact path="/survey" render={() => <Redirect to="/" />} />

      <UserRoute path="/spotlight/:chart_id" exact component={Spotlight} />
      <UserRoute path="/spotlight/details/:chart_id" exact component={Spotlight} />

      <UserRoute path="/boards" exact component={Dashboard} />

      <EditorRoute path="/templates" component={Collection} exact />
      <EditorRoute path="/templates/:category_tag" component={Template} exact />

      <EditorRoute path="/survey/:survey_id/editor" component={SurveyEditor} exact />
      <EditorRoute path="/prototype/:survey_id/editor" component={SurveyEditor} exact />
      <EditorRoute path="/quiz/:survey_id/editor" component={SurveyEditor} exact />

      <UserRoute path="/settings" exact component={SurveySettings} />
      <EditorRoute path="/cohorts" component={AudienceHome} exact />

      <EditorRoute path="/history/cohorts" component={CohortHistory} exact />
      <EditorRoute path="/cohorts/:cohort_id" component={CohortDetails} exact />

      <UserRoute path="/organisation/settings" component={OrganisationSettings} exact />
      <AdminRoute path="/organisation/billing" component={Billing} exact />
      <AdminRoute path="/organisation/billing-plan" component={BillingPlan} exact />

      <EditorRoute path="/connections" component={Connection} exact />

      <UserRoute path="/profile" exact component={Profile} />
      <UserRoute path="/add-details" exact component={AddDetails} />
      <UserRoute path="/org-details" component={OrgDetails} exact />

      <UserRoute path="/survey/:survey_id" component={SurveyPerformance} exact />
      <UserRoute path="/prototype/:survey_id" component={SurveyPerformance} exact />
      <UserRoute path="/quiz/:survey_id" component={SurveyPerformance} exact />

      <UserRoute path="/prioritise" component={InsightsPage} exact />
      <UserRoute path="/respository" component={RespositoryPage} exact />
      <UserRoute path="/respository/csv" component={CreateCSVPage} exact />

      <UserRoute path="/reports" component={ReportHomePage} exact />
      <UserRoute path="/reports/:report_id" component={ReportItemPage} exact />

      <Route path="*">
        <Redirect to="/error" />
      </Route>
    </Switch>
  );
};
