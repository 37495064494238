import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyStateContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    spotlightUpperContainer: {
      backgroundColor: theme.palette.info.light,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: '3.8%',
      paddingRight: '3.8%',
    },
    spotlightLowerContainer: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(3),
      paddingLeft: '3.8%',
      paddingRight: '3.8%',
    },
    spotlightBox: {
      backgroundColor: theme.palette.background.default,
      border: '1px solid #DEDEE9',
      borderRadius: theme.spacing(1.3),
      marginTop: theme.spacing(2),
      flexDirection: 'row',
    },
    spotlightLowerBox: {
      border: '1px solid #DEDEE9',
      borderRadius: theme.spacing(1.3),
    },
    greyBox: {
      backgroundColor: theme.palette.info.light,
      width: 'fit-content',
      paddingInline: theme.spacing(1),
      paddingTop: theme.spacing(0.6),
      paddingBottom: theme.spacing(0.7),
      borderRadius: '3px',
      paddingLeft: theme.spacing(2),
    },
    filterBox: {
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(3),
    },
    chartBox: {
      paddingBlock: theme.spacing(3.5),
      paddingInline: theme.spacing(4),
    },
    spotlightHeading: {
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(1),
      borderBottom: '1px solid #DEDEE9',
    },
    leftBox: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.3),
      height: '200px',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#F5F6FC',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#B7B7D2',
      },
    },
    hoverContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingBlock: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: '#F4F4FB',
        paddingBlock: theme.spacing(0.5),
      },
    },
    newQueryBox: {
      paddingLeft: '1.5rem',
    },
    rightBorder: {
      borderRight: '1px solid #DEDEE9',
    },
    rightBox: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.3),
      height: '200px',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#F5F6FC',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#B7B7D2',
      },
    },
    progress: {
      height: '13px',
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    splitViewBox: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    selectField: {
      border: `1px solid ${theme.palette.primary.contrastText}14`,
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '3px 11px',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: '32px',
        overflowY: 'auto',
      },
    },
    activeSelectField: {
      border: `1px solid #162EFF33`,
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
        color: theme.palette.primary.contrastText,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '3px 11px',
        backgroundColor: '#162EFF0D',
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: 25,
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .MuiSelect-icon': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiOutlinedInput-root': {
        '& $svg rect': { fill: theme.palette.primary.contrastText },
        '& $svg path': { fill: theme.palette.primary.contrastText },
      },
    },
    selectPlaceholder: {
      color: `${theme.palette.primary.contrastText}66`,
    },
    dashboardSelectField: {
      border: `1px solid ${theme.palette.primary.contrastText}14`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '0.5rem 0.87rem',
        fontSize: '0.9rem',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: 50,
        overflowY: 'auto',
      },
    },
    listSubtitle: {
      fontSize: '10px',
    },
    chartSelectField: {
      border: `1px solid ${theme.palette.primary.contrastText}14`,
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: '0.28rem 0.87rem',
        backgroundColor: theme.palette.info.dark,
      },
    },

    textField: {
      backgroundColor: theme.palette.info.dark,
      '& .MuiOutlinedInput-input': {
        padding: '0.42rem 0.87rem',
        fontSize: '0.9rem',
      },
    },

    toggleButtonGroup: { '&.MuiToggleButtonGroup-root': { display: 'flex' } },
    activeToggleButtonGroup: {
      '&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
      },
    },
    toggleButton: {
      color: theme.palette.text.primary,
      paddingInline: theme.spacing(1.5),
      paddingBlock: theme.spacing(0.6),
      '&:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}1A`,
      },
    },
    activeToggleButton: {
      color: theme.palette.primary.contrastText,
      paddingInline: theme.spacing(1.5),
      paddingBlock: theme.spacing(0.5),
      backgroundColor: `${theme.palette.primary.contrastText}1A`,
    },
    dateRangeBox: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      cursor: 'pointer',
      textTransform: 'capitalize',
    },
    dateRangePopup: {
      backgroundColor: 'white',
      '& .MuiDateRangePickerDay-day.Mui-selected': {
        willChange: 'none',
        backgroundColor: `${theme.palette.primary.contrastText}30`,
      },
      '& .MuiPaper-root': {
        left: 100,
      },
    },
    addNewBox: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      cursor: 'pointer',
      marginRight: theme.spacing(2.5),
    },
    sentimentBox: {
      maxWidth: 'fit-content',
      borderRadius: '2px',
      padding: theme.spacing(0.75),
      paddingBlock: theme.spacing(0.35),
      justifyContent: 'center',
    },
    actionBox: {
      padding: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '2px',
      width: '90px',
      justifyContent: 'center',
    },
    table: {
      '& .MuiTable-root .MuiTableCell-root': {
        border: 0,
      },
      '& .MuiTableCell-head': {
        fontSize: '0.8rem',
        fontWeight: 400,
        padding: 0,
        color: theme.palette.text.secondary,
      },
      '& .MuiTableHead-root': {
        borderTop: 0,
      },

      '& .MuiTableCell-body': {
        fontSize: '0.85rem',
        padding: 0,
        paddingBlock: '10px',
      },
      '& .MuiTableRow-root.MuiTableRow-hover:hover': {
        backgroundColor: `#F8F8FF`,
      },
    },
    searchTextField: {
      border: 'transparent',
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      marginBottom: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-input': {
        paddingRight: '0.6rem',
        paddingTop: '0.3rem ',
        paddingBottom: '0.5rem',
      },
    },
    multiListItem: {
      '& .MuiButtonBase-root': {
        paddingBlock: theme.spacing(1),
      },
      '&:hover': {
        backgroundColor: '#F8F8FF',
      },
    },
    searchList: {
      position: 'relative',
      width: '410px',
      maxHeight: 'unset',
    },
    selectHrLine: {
      borderColor: '#F0F0F826',
      marginBlockStart: theme.spacing(1),
      marginBlockEnd: theme.spacing(1),
    },
    applySelectBox: {
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'space-between',
      width: '100%',
      background: 'white',
      bottom: 0,
      marginTop: 10,
      marginLeft: 'auto',
      borderTop: '1px solid #E5E5E5',
      position: 'absolute',
      textAlign: 'right',
    },
    applyButton: {
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(1),
      paddingInline: theme.spacing(3),
      marginBlock: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    selectAllBox: {
      cursor: 'pointer',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.info.light,
      border: `1px solid ${theme.palette.primary.contrastText}33`,
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(0.3),
      borderRadius: '3px',
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      marginRight: theme.spacing(1),
      '&$checked': {
        color: theme.palette.primary.contrastText,
        marginRight: 8,
      },
    },
    checked: {},
    modalContainer: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },
    buttonSection: {
      marginTop: theme.spacing(2),
      display: 'flex',
      float: 'right',
    },
    nameField: {
      '& .MuiOutlinedInput-input': {
        padding: '0.6rem 0.87rem',
        fontSize: '0.9rem',
      },
    },
    pinButton: {
      paddingBlock: theme.spacing(0.7),
      paddingInline: theme.spacing(2.4),
    },
    avatar: {
      height: 30,
      width: 30,
    },
    npsStats: {
      fontSize: '1.37rem',
      fontWeight: 700,
      lineHeight: theme.spacing(2.5),
    },
    dashboardUpperBox: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: 0,
      backgroundColor: theme.palette.background.paper,
      paddingBlock: theme.spacing(1.6),
      paddingInline: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
    },
    dashboardLowerBox: {
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      borderTop: 0,
      paddingTop: theme.spacing(2),
      paddingInline: theme.spacing(3),
      paddingBottom: 0,
      height: `calc((92vw - 248px) * 0.3)`,
      overflowY: 'auto',
    },
    chartTitle: {
      fontWeight: 700,
      '&:hover': {
        color: '#224c96',
        textDecoration: 'underline',
      },
    },
    chartPublicTitle: { fontWeight: 700 },
    chartNameInput: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.text.disabled,
      border: 0,
      '& .MuiOutlinedInput-input': {
        fontWeight: 700,
        padding: 0,
        paddingBottom: theme.spacing(1),
        border: 0,
        fontSize: '2.19rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },
    dashboardNameInput: {
      color: theme.palette.text.disabled,
      border: 0,
      '& .MuiOutlinedInput-input': {
        fontWeight: 700,
        padding: 0,
        paddingBottom: theme.spacing(1),
        border: 0,
        fontSize: '2.19rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },
    questionMenu: {
      '& .MuiPaper-root': {
        width: '120px',
      },
    },
    optionButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    autoTextField: {
      borderRadius: theme.spacing(0.5),

      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.info.dark,
        padding: '4px 11px',
        minHeight: '30px',
        paddingInline: '0.87rem',
      },
      '& .MuiTextfield-root': {
        minHeight: '30px',
      },
      '& .MuiAutocomplete-tag': {
        border: `1px solid ${theme.palette.divider}`,
        marginBlock: 0,
      },
      '& .MuiAutocomplete-inputRoot': {
        minHeight: '30px',
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '1rem',
      },
      '& .MuiChip-root': {
        height: '22px',
      },
      '& .MuiAutocomplete-paper': {
        width: '400px',
      },
    },

    customTextField: {
      '& .MuiOutlinedInput-input': {
        paddingTop: '1px ',
        paddingBottom: '1px',
        minHeight: '30px',
        paddingInline: '0.87rem',
        backgroundColor: theme.palette.info.dark,
      },
    },
    deleteAttributeImg: {
      marginLeft: 10,
      marginTop: 5,
      cursor: 'pointer',
    },
    tooltipBox: {
      paddingTop: theme.spacing(1.3),
      paddingLeft: theme.spacing(1.2),
      paddingRight: theme.spacing(1.5),
      paddingBottom: theme.spacing(2),
      width: '235px',
    },
    addToButton: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid #CCCCCC`,
      color: theme.palette.text.primary,
      marginRight: theme.spacing(1),
      paddingBlock: theme.spacing(0.9),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        border: `1px solid #CCCCCC`,
        color: theme.palette.text.primary,
      },
    },
    addBoardMenu: {
      '& .MuiPaper-root': {
        width: '320px',
      },
    },
    menuItemBox: {
      paddingBottom: '50px',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#F5F6FC',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#B7B7D2',
      },
    },
    userBox: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    boardImgBox: {
      borderRadius: '50%',
      height: '24px',
      width: '24px',
      padding: theme.spacing(1.5),
    },
    takeawayOrderBox: {
      backgroundColor: '#5F516F',
      color: '#FFF',
      borderRadius: 5,
      padding: 6,
      paddingBlock: 2,
      width: 'fit-content',
    },
    dashboardPaper: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.7),
      cursor: 'pointer',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2.5),
      boxShadow: 'none',
    },
    reportTagText: {
      color: '#7d3e3e',
      backgroundColor: '#fff0bd',
      padding: '2px 10px',
      marginLeft: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      fontSize: 12,
      width: 'fit-content',
      borderRadius: 20,
    },
  }),
);
